import './App.css';
import clips from './data/clip_mapping.json'
import { Container } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

function App() {
  return (
    <div className="App">
      <Container sx={{ py: 2 }}>
        <Typography variant='h1'>
          Clip Explorer
        </Typography>
        <Typography align='left'>
          These clips were generated from transcribing the descriptive audio track from a blu-ray I personally own.
          Dialogue from subtitles was filtered out as quickly as possible so it isn't perfect.
          This is to gauge the feasibility of sourcing <i>quality</i> clips with audio foley en-masse.
        </Typography>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h5">
                    audio description
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h5">
                    clip
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clips.map((clip, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {clip.description}
                  </TableCell>
                  <TableCell align="right">
                    <video width='100%' key={clip.filename} src={'clips/' + clip.filename} type='video/mp4' allowFullScreen controls={true} preload='metadata' />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
}

export default App;
